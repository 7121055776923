import React from 'react';
import { withPrefix } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import footerChevron from '../theme/styles/footerChevron';

const styles = () => ({
  ...container,
  ...section,
  ...footerChevron,
});

const NotFoundPage = ({ classes, location }) => (
  <Layout location={location}>
    <div className={classes.container}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
    <div className={classes.footerChevronContainer}>
      <img
        src={withPrefix('/img/bottom--alt.svg')}
        alt=""
        className={classes.footerChevron}
      />
    </div>
  </Layout>
);

export default withRoot(withStyles(styles)(NotFoundPage));
